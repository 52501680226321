import BareButton from 'primitives/Buttons/BareButton';
import Bullet from 'components/Bullet';
import Dropdown from 'components/Dropdown/PlaylistDropdown';
import DummyTiles from '../DummyTiles';
import EmptyState from '../EmptyState';
import getUser from 'state/User/selectors';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/InfiniteScroll/Loader';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import PlaylistImage from 'components/MediaServerImage/PlaylistImage';
import PlaylistTypes from 'constants/playlistTypes';
import Row from '../Row';
import SectionHeaderText from '../SectionHeaderText';
import ShouldShow from 'components/ShouldShow';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import UPSELL from 'constants/upsellTracking';
import useMount from 'hooks/useMount';
import usePlaylistFeatures from 'hooks/usePlaylistFeatures';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { AddIcon, Author, Header } from './primitives';
import { ConnectedModals } from 'state/UI/constants';
import { Fragment, useCallback } from 'react';
import { getIsAnonymous, getProfileId } from 'state/Session/selectors';
import { getMyMusicCollections } from 'state/MyMusic/actions';
import {
  getNextPageKey,
  getOwnedAndFollowedPlaylists,
  getReceivedPlaylists,
} from 'state/Playlist/selectors';
import { H4, TileSubtitle, TilesWrapper } from '../primitives';
import { MY_MUSIC_CREATE_NEW_PLAYLIST } from 'modules/Analytics/constants/upsellFrom';
import { openModal, openSignupModal, openUpsellModal } from 'state/UI/actions';
import { Playlists as PlaylistsIcon } from 'styles/icons';
import { requestPlaylist } from 'state/Playlist/actions';
import { STATION_TYPE } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';
import { TILES_IN_ROW } from '../constants';
import { TRIGGER_FROM_TILE } from 'state/Playlist/constants';
import { useDispatch, useSelector } from 'react-redux';

import type { FunctionComponent } from 'react';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

const Playlists: FunctionComponent = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const { freeUserPlaylistCreation, canCreateFreePlaylist } =
    usePlaylistFeatures();
  const nextPageKey = useSelector(getNextPageKey);
  const playlists = useSelector(getOwnedAndFollowedPlaylists);
  const profileId = useSelector(getProfileId);
  const receivedPlaylists = useSelector(getReceivedPlaylists);
  const user = useSelector(getUser);
  const isAnonymous = useSelector(getIsAnonymous);

  const onCreatePlaylistClick = useCallback(() => {
    if (canCreateFreePlaylist) {
      dispatch(
        openModal({
          id: ConnectedModals.CreatePlaylist,
          context: {
            name: '',
            tracks: [],
            showGrowl: true,
          },
        }),
      );
    } else if (freeUserPlaylistCreation) {
      dispatch(openSignupModal({ context: 'add_to_playlist' }));
    } else {
      dispatch(
        openUpsellModal({
          upsellFrom: UPSELL.MY_MUSIC_CREATE_NEW_PLAYLIST,
          headerCopy: translate(
            'Access your music library and create unlimited playlists with iHeart All Access',
          ),
          analyticsUpsellFrom: MY_MUSIC_CREATE_NEW_PLAYLIST,
        }),
      );
    }
  }, [canCreateFreePlaylist, dispatch, freeUserPlaylistCreation, translate]);

  const loadMore = useCallback(async () => {
    if (!receivedPlaylists || nextPageKey) {
      dispatch(getMyMusicCollections(30, nextPageKey));
    }
  }, [dispatch, nextPageKey, receivedPlaylists]);

  // this is to prevent page error due to insufficient information in the playlist:playlists store.
  // example: the user followed the playlist externally with missing data to support this view
  // IHRWEB-14539
  const getNewlyFollowedPlaylists = useCallback(() => {
    const playlistIdsMissingData = playlists.filter(
      playlist => !playlist.tracks,
    );

    playlistIdsMissingData.forEach(playlist => {
      if (playlist.seedId) {
        const [playlistUserId, playlistId] = playlist.seedId.split('/');
        return dispatch(
          requestPlaylist({
            playlistId,
            playlistUserId,
          }),
        );
      }
      return null;
    });
  }, [dispatch, playlists]);

  useMount(() => {
    loadMore();
    getNewlyFollowedPlaylists();
  });

  let tiles = isAnonymous ? [] : [...DummyTiles({ noRoundTiles: true })];

  if (receivedPlaylists && !isAnonymous) {
    tiles = playlists.map(playlist => {
      const {
        author,
        curated,
        deletable,
        imgUrl,
        name,
        playableAsRadio,
        playlistId,
        seedId,
        tracks,
        type,
        userId,
        url,
        writeable,
      } = playlist;

      const myPlaylistPlayable =
        playableAsRadio ||
        user.subscription.subInfo.subscriptionType === 'PREMIUM';

      const tracksLength = tracks?.filter(track => !track.removed).length ?? 0;

      const playButtonComponent = (
        <ShouldShow
          shouldShow={
            tracksLength > 0 &&
            (type === PlaylistTypes.Default ? myPlaylistPlayable : true)
          }
        >
          <PlayButtonContainerPrimitive>
            <PlayButton
              className="play"
              playedFromTrigger={TRIGGER_FROM_TILE}
              playlistId={playlistId}
              playlistUserId={userId}
              seedId={seedId}
              stationId={seedId}
              stationType={
                playableAsRadio
                  ? STATION_TYPE.PLAYLIST_RADIO
                  : STATION_TYPE.COLLECTION
              }
            />
          </PlayButtonContainerPrimitive>
        </ShouldShow>
      );

      const imageComponent = (
        <TilesImageWrapper>
          <PlaylistImage
            alt={name}
            aspectRatio={1}
            background
            src={tracksLength ? imgUrl : ''}
            width={TILE_RES}
          />
        </TilesImageWrapper>
      );

      const songCount = `${tracksLength} ${translate('song{pluralSongs}', {
        pluralSongs: tracksLength === 1 ? '' : 's',
      })}`;

      const subtitle = (
        <TileSubtitle>
          <ShouldShow shouldShow={profileId !== Number(userId)}>
            <Author>{`${translate('by')} ${author}`}</Author>
            <Bullet />
          </ShouldShow>
          {songCount}
        </TileSubtitle>
      );

      return (
        <Fragment key={seedId}>
          <Row
            imageComponent={imageComponent}
            playButtonComponent={playButtonComponent}
            subtitleComponent={subtitle}
            title={name}
            url={url}
          />
          <Tile
            dataTest="your-library-playlists-tile"
            dropdown={
              <Dropdown
                canEditPlaylist={!curated}
                curated={curated}
                deletable={deletable}
                followed
                id={seedId}
                key={`dropdown-${seedId}`}
                name={name}
                playlistId={playlistId}
                tileType={type}
                tracks={tracks}
                url={url}
                userId={userId}
                writeable={writeable}
              />
            }
            noTileOnMobile
            subTitle={subtitle}
            tileDelay={0}
            tilesInRow={TILES_IN_ROW}
            title={name}
            url={url}
          >
            <NavLink to={url}>
              {playButtonComponent}
              {imageComponent}
            </NavLink>
          </Tile>
        </Fragment>
      );
    });
  }

  const noTiles = tiles.length === 0 || isAnonymous;

  return (
    <>
      <Header buttonOnly={noTiles}>
        <H4 data-test="your-library-playlists-title" hideHeader={noTiles}>
          <SectionHeaderText>{translate('Playlists')} (A-Z)</SectionHeaderText>
        </H4>

        <ShouldShow shouldShow={!noTiles}>
          <BareButton
            data-test="playlists-create-button"
            onClick={onCreatePlaylistClick}
          >
            <OutlinedButton size="small">
              <AddIcon />
              {translate('Create')}
            </OutlinedButton>
          </BareButton>
        </ShouldShow>
      </Header>

      <ShouldShow shouldShow={tiles.length > 0 || !receivedPlaylists}>
        <TilesWrapper>
          <InfiniteScroll
            hasMore={receivedPlaylists && !!nextPageKey}
            loader={<Loader />}
            loadMore={loadMore}
            pageStart={0}
          >
            <Tiles
              data-test="your-library-playlist-tiles"
              noTileOnMobile
              tilesInRow={TILES_IN_ROW}
            >
              {tiles}
            </Tiles>
          </InfiniteScroll>
        </TilesWrapper>
      </ShouldShow>

      <ShouldShow shouldShow={noTiles}>
        <EmptyState
          buttons={[
            <NavLink key="playlists-empty-link" to="/playlist/">
              <OutlinedButton>{translate('Browse Playlists')}</OutlinedButton>
            </NavLink>,
          ]}
          icon={PlaylistsIcon}
          subtitle={translate('Need a playlist to fit your mood?')}
          title={translate('Any playlists you follow will show up here')}
        />
      </ShouldShow>
    </>
  );
};

export default Playlists;
