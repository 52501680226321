import Albums from './Albums';
import Artists from './Artists';
import ContentMain from 'primitives/Layout/ContentMain';
import Footer from 'components/Footer/';
import Menu from './Menu';
import Playlists from './Playlists';
import Podcasts from './Podcasts';
import RecentlyPlayed from './RecentlyPlayed';
import SavedStations from './SavedStations';
import SelectedAlbum from './SelectedAlbum';
import SelectedArtist from './SelectedArtist';
import Songs from './Songs';
import { Container, PageWrapper, Section } from './primitives';
import { get, omit } from 'lodash-es';
import { Navigate } from 'state/Routing/types';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { SECTION_MAP_TYPE, SECTIONS_MAP, SectionType } from './sectionsMap';
import { useCallback, useMemo, useState } from 'react';

type Props = {
  adFree: boolean;
  countryCode: string;
  internationalPlaylistRadioEnabled: boolean;
  myMusicLibrary: boolean;
  navigate: Navigate;
  onDemandEnabled: boolean;
  section: SectionType;
  subInfoLoaded: boolean;
};

function YourLibrary({
  adFree,
  countryCode,
  internationalPlaylistRadioEnabled,
  myMusicLibrary,
  navigate,
  onDemandEnabled,
  section,
  subInfoLoaded,
}: Props) {
  const [currentSection, setCurrentSection] = useState(
    section || 'recently-played',
  );

  const sectionInfo = useMemo(
    () => get(SECTIONS_MAP, currentSection),
    [currentSection],
  );

  // if countryCode is WW, user shouldn't have access to live stations
  const sections = (
    countryCode === 'WW' ? omit(SECTIONS_MAP, 'saved-stations') : SECTIONS_MAP
  ) as SECTION_MAP_TYPE;

  // if section needs to check OD flag, then check that otherwise true
  const hasOnDemandAccess = useMemo(
    () => (get(sectionInfo, 'needsOnDemand') ? onDemandEnabled : true),
    [onDemandEnabled, sectionInfo],
  );
  // if section needs to check my music feature flag, then check that otherwise true
  const hasMyMusic = useMemo(
    () => (get(sectionInfo, 'needsPermission') ? myMusicLibrary : true),
    [myMusicLibrary, sectionInfo],
  );
  const hasInternationalPlaylistRadio = useMemo(
    () =>
      get(sectionInfo, 'canHaveInternationalPlaylistRadio')
        ? internationalPlaylistRadioEnabled
        : true,
    [internationalPlaylistRadioEnabled, sectionInfo],
  );
  const doesntHavePermission = useMemo(
    () =>
      subInfoLoaded &&
      (!hasMyMusic || !(hasOnDemandAccess || hasInternationalPlaylistRadio)),
    [
      hasInternationalPlaylistRadio,
      hasOnDemandAccess,
      hasMyMusic,
      subInfoLoaded,
    ],
  );

  const selectSection = useCallback(
    (newSection: SectionType) => {
      setCurrentSection(newSection);
      navigate({ path: `/your-library/${newSection}/` });
    },
    [navigate],
  );

  if (doesntHavePermission && __CLIENT__) {
    return <Redirect to={{ pathname: '/your-library/' }} />;
  }

  return (
    <ContentMain>
      <Container>
        <PageWrapper>
          <Menu
            currentSection={currentSection}
            sections={sections}
            selectSection={selectSection}
          />

          <Section
            data-test="your-library-section"
            fullMobileHeight={get(SECTIONS_MAP, [
              currentSection,
              'fullMobileHeight',
            ])}
            hasAd={!adFree}
          >
            <Route component={RecentlyPlayed} exact path="/your-library/" />
            <Route
              component={RecentlyPlayed}
              exact
              path="/your-library/recently-played/"
            />
            <Route
              component={SavedStations}
              exact
              path="/your-library/saved-stations/"
            />
            <Route component={Podcasts} exact path="/your-library/podcasts/" />
            <Route
              component={Playlists}
              exact
              path="/your-library/playlists/"
            />
            <Route component={Artists} exact path="/your-library/artists/" />
            <Route
              component={SelectedArtist}
              exact
              path="/your-library/artists/:slugifiedId"
            />
            <Route component={Albums} exact path="/your-library/albums/" />
            <Route
              component={SelectedAlbum}
              exact
              path="/your-library/albums/:slugifiedId"
            />
            <Route component={Songs} exact path="/your-library/songs/" />
          </Section>
        </PageWrapper>
      </Container>
      <Footer />
    </ContentMain>
  );
}

export default YourLibrary;
